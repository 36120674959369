import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Row, Cell, HeroCard, Section } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { RCL, RCL as useTranslation } from "../RCL";
import { ProfileContext } from "../Account/context/ProfileProvider";
import useGatsbyAuth from "../Account/hook/useGatsbyAuth";
import { LayoutWrapper } from "../Account/LayoutWrapper";
import { routingPaths } from "../Account/config";
import {
	getAccountCreatedStatus,
	replaceWithJSX,
	getURLQueries,
} from "../utils/helpers/helper";

import styles from "./Login.module.scss";

const Login = ({ language, isShowAccess, isShowSignup }) => {
	dayjs.locale(language);
	dayjs.extend(utc);

	const dateFormat =
		language === "fr"
			? "dddd D MMM YYYY à HH [h] mm"
			: "ddd, MMM D, YYYY h:mm A";

	const { profile, updateProfile } = useContext(ProfileContext);
	const { authService, authState, loading } = useGatsbyAuth();
	const navigate = useNavigate();
	const [isAgentUserMBP, setAgentUserMBP] = useState(false);
	const [widgetFlow, setWidgetFlow] = useState();
	const isAccountCreated = useRef(getAccountCreatedStatus());
	const [isAccountVerified, setIsAccountVerified] = useState(false);
	const [isModalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		let isCheckSessionSubscribed = true;
		let isSetSessionSubscribed = true;
		(async () => {
			if (!loading) {
				if (authState && !authState?.isAuthenticated) {
					await authService.session.exists().then(exists => {
						if (isCheckSessionSubscribed) {
							if (
								exists &&
								authState.isAuthenticated &&
								!authState.idToken.claims.agentGlobalId
							) {
								authService.token.getWithoutPrompt().then(res => {
									const { tokens } = res;

									if (!isAccountCreated?.current) {
										authService.tokenManager.setTokens(tokens);
										navigate(`/${language}/${routingPaths[language].home}`);
									} else if (isAccountCreated?.current) {
										// Clear session if user was logged in already
										authService.closeSession();
										localStorage.removeItem("profile");
										setWidgetFlow("login");
										window.location.reload();
									}
								});
							} else if (
								exists &&
								authState.isAuthenticated &&
								authState.idToken.claims.agentGlobalId
							) {
								setAgentUserMBP(true);
								navigate(`/${language}/${routingPaths[language].agent}`);
							} else if (!exists && isAccountCreated?.current) {
								setWidgetFlow("login");
								isAccountCreated.current = false;
							} else if (!exists && !widgetFlow) {
								if (getURLQueries("token") || getURLQueries("action")) {
									setWidgetFlow("login");
								} else {
									setWidgetFlow("signup");
								}
							}
						}
					});
				} else if (
					authService &&
					authState === null &&
					!profile.isUserLoggedIn
				) {
					await authService.session.exists().then(exists => {
						if (isSetSessionSubscribed) {
							if (exists) {
								authService.token
									.getWithoutPrompt()
									.then(res => {
										const { tokens } = res;
										authService.tokenManager.setTokens(tokens);

										updateProfile({
											isUserLoggedIn: true,
											firstName: tokens.idToken.claims.firstName ?? "",
											lastName: tokens.idToken.claims.lastName ?? "",
											agentGlobalId: tokens.idToken.claims.agentGlobalId ?? "",
										});

										navigate(`/${language}/${routingPaths[language].home}`);
									})
									.catch(error => {
										console.error("Login getWithoutPrompt error:", error);
									});
							}
						}
					});
				} else if (authState.isAuthenticated) {
					navigate(`/${language}/${routingPaths[language].home}`);
				}
			}
		})();

		return () => {
			isCheckSessionSubscribed = false;
			isSetSessionSubscribed = false;
		};
	}, [
		authService,
		authState,
		isAccountCreated,
		language,
		loading,
		navigate,
		profile.isUserLoggedIn,
		updateProfile,
		widgetFlow,
	]);

	const dictionary = {
		"qba-header-meta-title-login": useTranslation({
			searchKey: "qba-header-meta-title-login",
		}),
		"qba-header-meta-description-login": useTranslation({
			searchKey: "qba-header-meta-description-login",
		}),
		"qba-login-heading": useTranslation({ searchKey: "qba-login-heading" }),
		"qba-login-image": useTranslation({ searchKey: "qba-login-image" }),
		"qba-login-message": useTranslation({ searchKey: "qba-login-message" }),
	};

	let loginMessage = dictionary["qba-login-message"];

	loginMessage = replaceWithJSX(
		loginMessage,
		"{SIGNUP_DATE}",

		dayjs(process.env.REACT_APP_SIGNUP_CUTOFF)
			.utcOffset(-4, false)
			.subtract(process.env.REACT_APP_SIGNUP_DAYS, "days")
			.format(dateFormat)
	);

	loginMessage = replaceWithJSX(
		loginMessage,
		"{SIGNUP_DATE_CUTOFF}",
		dayjs(process.env.REACT_APP_SIGNUP_CUTOFF)
			.utcOffset(-4, false)
			.format(dateFormat)
	);

	loginMessage = replaceWithJSX(
		loginMessage,
		"{DECOMISSION_DATE}",
		dayjs(process.env.REACT_APP_ACCESS_CUTOFF)
			.utcOffset(-4, false)
			.format(dateFormat)
	);

	if (
		isAgentUserMBP ||
		authState?.isAuthenticated ||
		loading ||
		isShowAccess === undefined
	) {
		return null;
	}

	return (
		<Section>
			{isShowAccess ? (
				<>
					<Helmet>
						<meta
							name="description"
							content={dictionary["qba-header-meta-description-login"]}
						/>
						<meta
							property="og:title"
							content={dictionary["qba-header-meta-title-login"]}
						/>
						<meta
							property="og:description"
							content={dictionary["qba-header-meta-description-login"]}
						/>
						<title>{dictionary["qba-header-meta-title-login"]}</title>
					</Helmet>
					<Row cols={2}>
						<Cell>
							{dictionary["qba-login-image"] && (
								<HeroCard
									backgroundColor="#fff"
									image={{
										alt: "",
										src: dictionary["qba-login-image"],
									}}
								/>
							)}
							<Section
								title={dictionary["qba-login-heading"]}
								titleAlignment="left"
								className={styles.loginTitle}
							>
								{loginMessage}
							</Section>
						</Cell>
						<Cell>
							{!authState?.isAuthenticated && widgetFlow && (
								<LayoutWrapper
									language={language}
									isShowSignup={isShowSignup}
									isAccountCreated={isAccountCreated?.current}
									isAccountVerified={isAccountVerified}
									setIsAccountVerified={setIsAccountVerified}
									widgetFlow={widgetFlow}
									setWidgetFlow={setWidgetFlow}
									setModalOpen={setModalOpen}
									isModalOpen={isModalOpen}
								/>
							)}
							&nbsp;
						</Cell>
					</Row>
				</>
			) : (
				<>
					<Helmet>
						<meta
							property="og:title"
							content={dictionary["qba-header-meta-title-login"]}
						/>
						<title>{dictionary["qba-header-meta-title-login"]}</title>
					</Helmet>
					<RCL searchKey="qba-decommissioned-message" />
				</>
			)}
		</Section>
	);
};

Login.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	isShowAccess: PropTypes.bool,
	isShowSignup: PropTypes.bool.isRequired,
};

Login.defaultProps = {
	isShowAccess: undefined,
};

export default Login;
export { Login };
