/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { config, socialLogins } from "../../config";
import { getURLQueries } from "../../../utils/helpers/helper";
import "./themeClassic.scss";

let clearIntervalState = false;
let observer = null;
const delay = 1500;
const programId = window.btoa(process.env.REACT_APP_PROGRAM_ID);
let isSocialDisabled = false;
const updateContext = async (uid, context) => {
	// Update profile context

	const apiRequest = new Request(
		`${process.env.REACT_APP_SWG_APIM_API}/auth/client/current`
	);

	return fetch(apiRequest, {
		headers: {
			"Content-type": "application/json",
		},
		method: "PATCH",
		body: JSON.stringify({
			email: uid,
			contactType: "CUSTOMER",
			additionalProperties: [
				{
					code: "clientId",
					value: context,
				},
			],
		}),
	})
		.then(async res => {
			if (!res.ok) {
				if (res.status !== 204) {
					throw new Error(`${res.status} ${res.statusText}`);
				}
			}
			return res;
		})
		.catch(error => {
			console.error("updateContext method failed: ", error);
			return error;
		});
};

const renderError = (_ref, message) => {
	// Display user input error
	const errorElement = _ref.querySelector(".o-form-error-container");
	errorElement.classList.add("o-form-has-errors");
	if (message) {
		errorElement.innerHTML = `<div><div class="okta-form-infobox-error infobox infobox-error" role="alert"><span class="icon error-16"></span><p>${message}</p></div></div>`;
	} else {
		errorElement.innerHTML = "";
	}
};

const cleanQueryURL = _queries => {
	const siteUrl = new URL(window.location.href);
	const queries = siteUrl.searchParams;

	_queries.forEach(query => {
		queries.set(query, "");
	});

	siteUrl.search = queries.toString();

	const obj = { Title: document.title, Url: siteUrl.toString() };
	window.history.pushState(obj, obj.Title, obj.Url);
};

const OktaSignInWidgetClassic = ({
	lang,
	isAccountCreated,
	isAccountVerified,
	setIsAccountVerified,
	isActionDefault,
	dictionary,
	isShowSignup,
	className,
	email,
	setWidgetFlow,
	isSocialNotify,
	setDisplaySocialModal,
	setSocialDetails,
	selectedIdp,
	verifyStatus,
	setVerifyStatus,
}) => {
	isSocialDisabled = isSocialNotify;
	const widgetRef = useRef(null);
	const isAction = isActionDefault || getURLQueries("action");
	const loginHint = getURLQueries("login_hint");
	const legalCopy = dictionary["okta-widget-legal"];
	const customHelpLinksRCL = dictionary["okta-helplinks-custom-qba"];

	const processHelpLinks = linksCopy => {
		if (typeof linksCopy !== "string") {
			return null;
		}
		const textLinks = linksCopy.trim().replace(/\n/g, "").split(",");
		const linksArray = [];

		textLinks.forEach(textLink => {
			const textArray = textLink.trim().split(";");

			const linkObject = {
				text: "",
				href: "",
				target: null,
			};

			let linkFlag = false;

			for (let i = 0; i < textArray.length; i++) {
				// Sanitize array
				const tempString = textArray[i].trim();

				if (tempString.length) {
					textArray[i] = tempString;
				} else {
					textArray[i] = null;
				}

				// Build output array
				if (textArray[i]) {
					// eslint-disable-next-line no-loop-func
					Object.keys(linkObject).forEach(key => {
						const textItem = textArray[i].split(":");

						if (key === textItem[0]) {
							switch (key) {
								case "text":
									// eslint-disable-next-line prefer-destructuring
									linkObject[key] = textItem[1];
									linkFlag = true;
									break;
								case "href":
									// eslint-disable-next-line no-case-declarations
									const href = textArray[i].substr(
										key.length + 1,
										textArray[i].length - 1
									);
									linkObject[key] = href;
									linkFlag = true;
									break;
								case "target":
									// eslint-disable-next-line prefer-destructuring
									linkObject[key] = textItem[1];
									linkFlag = true;
									break;
								default:
									linkFlag = false;
							}
						}
					});
				}
			}

			// Update output array
			if (linkFlag && linkObject.text && linkObject.href) {
				linksArray.push(linkObject);
			}
		});

		if (linksArray) {
			return linksArray;
		}
		return null;
	};

	const customHelpLinks = processHelpLinks(customHelpLinksRCL);

	// Build IDP array
	const socialLoginArray = socialLogins.map(idp => ({
		...idp,
		text: " ",
	}));

	const interceptModal = (e, idp) => {
		if (isSocialDisabled) {
			e.preventDefault();
			e.stopPropagation();

			setSocialDetails({
				idp,
			});
			setDisplaySocialModal(true);
		}
	};

	const updateSocialButtons = (instance, id) => {
		// Social media buttons
		const socialMediaButtons = instance.querySelectorAll(
			".social-auth-button.link-button"
		);

		if (socialMediaButtons) {
			// Remove text and add accessibility label
			let buttonClasses = "";
			let idp = "";

			socialMediaButtons.forEach(button => {
				let isFound = false;
				buttonClasses = button.className;

				socialLoginArray.forEach(idpButton => {
					idp = idpButton.type.toLowerCase();

					if (!isFound && buttonClasses) {
						if (buttonClasses?.indexOf(idp) > -1) {
							button.ariaLabel = dictionary[`okta-widget-sign-in-with-${idp}`];
							isFound = true;

							if (isSocialDisabled && idpButton.notify) {
								button.addEventListener(
									"click",
									e => interceptModal(e, idpButton),
									true
								);
							} else if (id === idpButton.id) {
								button.click();
							}
						} else {
							button.ariaLabel = "";
						}
					}
				});
			});
		}
	};

	// _ref = widget reference,
	// _form = widget forms: register | sign-in | unlock | forgot | send-email | password-reset
	const updateWidget = (_ref, _form) => {
		const widgetLoadInterval = setInterval(() => {
			// Form screens
			const isRegistrationForm = _ref.querySelector(".registration");
			const isRegistrationComplete = _ref.querySelector(
				".registration-complete"
			);
			const isLoginForm = _ref.querySelector(".primary-auth");

			const isForgotPasswordForm = _ref.querySelector(".forgot-password");
			const isPasswordResetForm = _ref.querySelector(
				".password-reset-email-sent"
			);

			const isUnlockAccountForm = _ref.querySelector(".account-unlock");
			const isEmailSentForm = _ref.querySelector(".account-unlock-email-sent");

			// Form element target for legal
			const contentDIV = _ref.getElementsByClassName("auth-content")[0];

			// Back links
			const signUpElement = _ref.getElementsByClassName("registration-link")[0];
			const registerElement = _ref.getElementsByClassName("link help")[0];
			const registerBackLinkElement =
				_ref.getElementsByClassName("back-btn")[0];

			// Forgot password form
			const forgotBackLinkElement =
				isForgotPasswordForm?.querySelector(".js-back");
			const forgotResetButtonElement =
				isForgotPasswordForm?.querySelector(".link-button");

			const resetBackButtonElement =
				isPasswordResetForm?.querySelector(".link-button");

			// Unlock form
			const unlockBackLinkElement =
				isUnlockAccountForm?.querySelector(".js-back");
			const unlockButtonElement =
				isUnlockAccountForm?.querySelector(".link-button");

			const unlockBackButtonElement =
				isEmailSentForm?.querySelector(".link-button");

			// Register form
			const createAccountElement = _ref.getElementsByClassName(
				"button button-primary"
			)[0];

			const oktaHeader = _ref.getElementsByClassName(
				"okta-sign-in-header"
			)?.[0];

			// Remove account created message
			if (oktaHeader?.classList.contains("activate")) {
				oktaHeader.classList.remove("activate");
				setIsAccountVerified(false);
			}

			if (_form === "register") {
				if (isRegistrationForm && !isLoginForm) {
					// Check if legal copy exists in widget
					const legalElementDIV = _ref.querySelector(".legal");
					if (!legalElementDIV) {
						// Inject legal copy into widget
						const legalDIV = document.createElement("div");
						legalDIV.innerHTML = legalCopy;
						legalDIV.className = "legal";

						contentDIV.appendChild(legalDIV);
					}

					if (registerElement) {
						const registerMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							registerElement.getAttribute("data-click") === "true";

						if (!clickState) {
							registerElement.setAttribute("data-click", true);
							registerElement.addEventListener("click", registerMethod, true);
						}

						const resetMethod = () => {
							setTimeout(() => {
								updateWidget(_ref, "registration-complete");
							}, 1000);
						};

						const accountClickState =
							createAccountElement.getAttribute("data-click") === "true";

						if (!accountClickState) {
							createAccountElement.setAttribute("data-click", true);
							createAccountElement.addEventListener("click", resetMethod, true);
						}
					}
				}
			} else if (_form === "registration-complete") {
				if (isRegistrationComplete) {
					setTimeout(() => {
						// Remove all legal
						const legalDIV = _ref.querySelectorAll(".legal");

						if (legalDIV.length > 0) {
							legalDIV.forEach(item => {
								item.parentNode.removeChild(item);
							});
						}
					}, 500);

					if (registerBackLinkElement) {
						const registerBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							registerBackLinkElement.getAttribute("data-click") === "true";

						if (!clickState) {
							registerBackLinkElement.setAttribute("data-click", true);
							registerBackLinkElement.addEventListener(
								"click",
								registerBackMethod,
								true
							);
						}
					}
				}
			} else if (_form === "sign-in") {
				if (isLoginForm && !isRegistrationForm && !isRegistrationComplete) {
					// Remove legal copy and help link from widget
					const legalDIV = _ref.querySelector(".legal");
					if (legalDIV) {
						legalDIV.parentNode.removeChild(legalDIV);
					}

					// Remove help link
					const helpElement = _ref.getElementsByClassName("js-help-link")[0];
					if (helpElement) {
						helpElement.parentNode.removeChild(helpElement);
					}

					const forgotElement =
						_ref.getElementsByClassName("js-forgot-password")[0];
					if (forgotElement) {
						const setUpForgotMethod = () => {
							updateWidget(_ref, "forgot");
						};

						const clickState =
							forgotElement.getAttribute("data-click") === "true";

						if (!clickState) {
							forgotElement.setAttribute("data-click", true);
							forgotElement.addEventListener("click", setUpForgotMethod, true);
						}
					}

					const unlockElement = _ref.getElementsByClassName("js-unlock")[0];
					if (unlockElement) {
						const setUpForgotMethod = () => {
							updateWidget(_ref, "unlock");
						};

						const clickState =
							unlockElement.getAttribute("data-click") === "true";

						if (!clickState) {
							unlockElement.setAttribute("data-click", true);
							unlockElement.addEventListener("click", setUpForgotMethod, true);
						}
					}

					if (signUpElement) {
						const registrationContainer = signUpElement.closest(
							".registration-container"
						);

						if (isShowSignup) {
							const signUpMethod = e => {
								e.preventDefault();
								setWidgetFlow("signup");
							};
							const clickState =
								signUpElement.getAttribute("data-click") === "true";

							if (!clickState) {
								signUpElement.setAttribute("data-click", true);
								signUpElement.addEventListener("click", signUpMethod, true);
							}
							registrationContainer.classList.add("show");
						} else {
							registrationContainer.parentNode.removeChild(
								registrationContainer
							);
						}
					}

					// IDP Discovery Next Button
					const nextButton = _ref.querySelectorAll("#idp-discovery-submit")[0];
					if (nextButton) {
						const nextButtonMethod = () => {
							setTimeout(() => {
								updateWidget(_ref, "sign-in");
							}, delay);
						};

						const clickState = nextButton.getAttribute("data-click") === "true";

						if (!clickState) {
							nextButton.setAttribute("data-click", true);
							nextButton.addEventListener("click", nextButtonMethod, true);
						}
					}

					if (loginHint) {
						// Auto fill the email when new account creation is sucessful
						const username = _ref.querySelectorAll(
							"#idp-discovery-username"
						)[0];
						if (username) {
							username.value = decodeURIComponent(loginHint);
							username.dispatchEvent(new Event("change", { bubbles: true }));
							if (nextButton) {
								nextButton.focus();
							}
						}
					}

					// Social media buttons
					updateSocialButtons(_ref);
				}
			} else if (_form === "forgot") {
				if (isForgotPasswordForm) {
					if (forgotBackLinkElement) {
						const signinBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							forgotBackLinkElement.getAttribute("data-click") === "true";

						if (!clickState) {
							forgotBackLinkElement.setAttribute("data-click", true);
							forgotBackLinkElement.addEventListener(
								"click",
								signinBackMethod,
								true
							);
						}
					}

					if (forgotResetButtonElement) {
						const resetMethod = e => {
							const inputElement = _ref.querySelector(
								"#account-recovery-username"
							);

							if (inputElement.value.length) {
								// Block the submission
								e.preventDefault();
								e.stopPropagation();

								// Start custom forgot email flow by disabling input
								inputElement.setAttribute("disabled", true);

								updateContext(inputElement.value, programId).then(res => {
									if (res.status === 204) {
										// Dynamically trigger the button to resume the flow
										forgotResetButtonElement.removeEventListener(
											"click",
											resetMethod,
											true
										);

										forgotResetButtonElement.click();

										setTimeout(() => {
											// Clean profile with delay to prevent a race condition
											updateContext(inputElement.value, "");
											inputElement.removeAttribute("disabled");
										}, 3000);
									} else {
										// If a valid user is NOT found
										inputElement.removeAttribute("disabled");
										// Update UI to tell user account is not valid / generic messaage
										renderError(_ref, dictionary["okta-email-flow-error"]);
									}
								});
							} else {
								renderError(_ref, "");
							}

							setTimeout(() => {
								updateWidget(_ref, "password-reset");
							}, 1000);
						};

						const clickState =
							forgotResetButtonElement.getAttribute("data-click") === "true";

						if (!clickState) {
							forgotResetButtonElement.setAttribute("data-click", true);
							forgotResetButtonElement.addEventListener(
								"click",
								resetMethod,
								true
							);
						}
					}
				}
			} else if (_form === "password-reset") {
				if (isPasswordResetForm) {
					if (resetBackButtonElement) {
						const signinBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							resetBackButtonElement.getAttribute("data-click") === "true";

						if (!clickState) {
							resetBackButtonElement.setAttribute("data-click", true);
							resetBackButtonElement.addEventListener(
								"click",
								signinBackMethod,
								true
							);
						}
					}
				}
			} else if (_form === "unlock") {
				if (isUnlockAccountForm) {
					if (unlockBackLinkElement) {
						const signinBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							unlockBackLinkElement.getAttribute("data-click") === "true";

						if (!clickState) {
							unlockBackLinkElement.setAttribute("data-click", true);
							unlockBackLinkElement.addEventListener(
								"click",
								signinBackMethod,
								true
							);
						}
					}

					if (unlockButtonElement) {
						const resetMethod = e => {
							const inputElement = _ref.querySelector(
								"#account-recovery-username"
							);

							if (inputElement.value.length) {
								// Block the submission
								e.preventDefault();
								e.stopPropagation();

								// Start custom unlock email flow by disabling input
								inputElement.setAttribute("disabled", true);

								updateContext(inputElement.value, programId).then(res => {
									if (res.status === 204) {
										// Dynamically trigger the button to resume the flow
										unlockButtonElement.removeEventListener(
											"click",
											resetMethod,
											true
										);

										unlockButtonElement.click();

										setTimeout(() => {
											// Clean profile with delay to prevent a race condition
											updateContext(inputElement.value, "");
											inputElement.removeAttribute("disabled");
										}, 3000);
									} else {
										// If a valid user is NOT found
										inputElement.removeAttribute("disabled");
										// Update UI to tell user account is not valid / generic messaage
										renderError(_ref, dictionary["okta-email-flow-error"]);
									}
								});
							} else {
								renderError(_ref, "");
							}

							setTimeout(() => {
								updateWidget(_ref, "send-email");
							}, 1000);
						};

						const clickState =
							unlockButtonElement.getAttribute("data-click") === "true";

						if (!clickState) {
							unlockButtonElement.setAttribute("data-click", true);
							unlockButtonElement.addEventListener("click", resetMethod, true);
						}
					}
				}
			} else if (_form === "send-email") {
				if (isEmailSentForm) {
					if (unlockBackButtonElement) {
						const signinBackMethod = () => {
							updateWidget(_ref, "sign-in");
						};

						const clickState =
							unlockBackButtonElement.getAttribute("data-click") === "true";

						if (!clickState) {
							unlockBackButtonElement.setAttribute("data-click", true);
							unlockBackButtonElement.addEventListener(
								"click",
								signinBackMethod,
								true
							);
						}
					}
				}
			}

			if (widgetLoadInterval && !clearIntervalState) {
				clearIntervalState = true;
				setTimeout(() => {
					clearIntervalState = false;
					clearInterval(widgetLoadInterval);
				}, 2000);
			}
		}, 150);
	};

	const setupUnlockAccountObserver = _ref => {
		// Select the target node
		const target = _ref.getElementsByClassName("auth-content-inner")[0];

		// Create an observer instance
		observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.type === "childList") {
					// Handle automatic unlock behaviour
					if (mutation.nextSibling?.className === "account-unlock") {
						updateWidget(_ref, "unlock");
					} else if (mutation.nextSibling?.className === "registration") {
						updateWidget(_ref, "sign-in");
					}
				}
			});
		});

		// Configuration of the observer
		const observerConfig = { attributes: false, childList: true };

		if (target) {
			// Tell user agent to start observing
			observer.observe(target, observerConfig);
		}
	};

	const widgetConfig = {
		...config,
		baseUrl: config.domain,
		redirectUri: `${window.location.origin}/${lang}/login/callback`,
		authParams: {
			issuer: config.issuer,
			responseType: config.responseType,
			scopes: config.scopes,
			display: "page",
			pkce: true,
			responseMode: "query",
		},
		language: lang,
		features: {
			registration: true,
			rememberMe: true,
			showPasswordToggleOnSignInPage: true,
			selfServiceUnlock: true,
			idpDiscovery: false,
		},
		helpLinks: {
			custom: customHelpLinks,
		},
		i18n: {
			[lang]: {
				// COMMON LABELS
				needhelp: dictionary["okta-widget-need-help"],
				help: " ",
				forgotpassword: dictionary["okta-widget-forgot-password"],
				"primaryauth.username.placeholder":
					dictionary["okta-widget-email-placeholder"],
				"primaryauth.username.tooltip":
					dictionary["okta-widget-email-placeholder"],
				"idpDiscovery.email.placeholder":
					dictionary["okta-widget-email-placeholder"],
				unlockaccount: dictionary["okta-widget-unlock-account"],
				"socialauth.divider.text": dictionary["okta-widget-social-text-v2"],

				// ERRORS
				"errors.E0000004": dictionary["okta-widget-error-E0000004"],
				"errors.E0000006": dictionary["okta-widget-error-E0000006"],
				"errors.E0000119": dictionary["okta-widget-error-E0000119"],

				/* NOTE: Currently Okta does not pickup these password strength translations */
				"registration.passwordComplexity.minLength":
					dictionary["okta-widget-chars"],
				"registration.passwordComplexity.minLower":
					dictionary["okta-widget-lowercase"],
				"registration.passwordComplexity.minUpper":
					dictionary["okta-widget-uppercase"],
				"registration.passwordComplexity.minNumber":
					dictionary["okta-widget-numbers"],
				"registration.passwordComplexity.minSymbol":
					dictionary["okta-widget-symbols"],
				"registration.passwordComplexity.excludeUsername":
					dictionary["okta-widget-username"],

				// REGISTRATION LABELS
				"registration.signup.label": dictionary["okta-widget-sign-up-label"],
				"registration.signup.text": dictionary["okta-widget-sign-up-text"],
				"registration.form.title": dictionary["okta-widget-create-an-account"],
				"registration.form.submit": dictionary["okta-widget-create-an-account"],

				"registration.complete.confirm.text":
					dictionary["okta-widget-sign-up-confirm-text"],
			},
		},
		idps: socialLoginArray,
		recoveryToken: getURLQueries("token"),
		useClassicEngine: true,
		registration: {
			parseSchema: (schema, onSuccess) => {
				// Reset profileSchema to set up new order
				schema.profileSchema.fieldOrder = [];

				schema.profileSchema.properties.email.title =
					dictionary["okta-widget-email-placeholder"];
				schema.profileSchema.fieldOrder.push("email");

				schema.profileSchema.properties.password.title =
					dictionary["okta-widget-password"];
				schema.profileSchema.fieldOrder.push("password");

				schema.profileSchema.properties.confirmPassword = {
					type: "password",
					description: dictionary["okta-widget-confirm-password"],
					default: dictionary["okta-widget-password"],
					title: dictionary["okta-widget-confirm-password"],
				};
				schema.profileSchema.fieldOrder.push("confirmPassword");

				/* TODO: Temporary solution to fix localization bug */
				// Setup localizations for passwordComplexity

				if (lang === "fr") {
					const myPasswordRuleDescriptionOverride = {
						minLength: dictionary["okta-widget-chars"],
						"/[\\d]+/": dictionary["okta-widget-numbers"],
						"/[-!$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\\\\\/@#]+/":
							dictionary["okta-widget-symbols"],
						"/[a-z]+/": dictionary["okta-widget-lowercase"],
						"/[A-Z]+/": dictionary["okta-widget-uppercase"],
						"/^[#/userName]/": dictionary["okta-widget-username"],
					};

					const passwordRules = schema.profileSchema.properties.password.allOf;

					passwordRules.forEach(rule => {
						if (
							typeof rule.minLength === "number" &&
							myPasswordRuleDescriptionOverride.minLength
						) {
							rule.description = dictionary["okta-widget-chars"].replace(
								"{0}",
								rule.minLength
							);
						} else if (
							rule.format === "/^[#/userName]/" &&
							myPasswordRuleDescriptionOverride[rule.format]
						) {
							rule.description = myPasswordRuleDescriptionOverride[rule.format];
						} else if (
							rule.format &&
							myPasswordRuleDescriptionOverride[rule.format]
						) {
							rule.description = myPasswordRuleDescriptionOverride[
								rule.format
							].replace("{0}", 1);
						}
					});
				}

				onSuccess(schema);
			},
			preSubmit: (postData, onSuccess, onFailure) => {
				if (postData.password === postData.confirmPassword) {
					postData.preferredLanguage = lang;
					postData.locale = lang.concat("_CA");
					postData.subscribeUpdateDate = new Date().toISOString();
					postData.createdSource = process.env.REACT_APP_CREATED_SOURCE;
					onSuccess(postData);
				} else {
					const error = {
						errorSummary: "API Error",
						errorCauses: [
							{
								errorSummary: dictionary["okta-widget-confirm-password-error"],
								reason: "registration.error.confirmPassword",
								resource: "User",
								property: "confirmPassword",
								arguments: [],
							},
						],
					};
					onFailure(error);
					console.error("preSubmit:: error: ", error);
				}
			},
			postSubmit: async (response, onSuccess) => {
				updateWidget(widgetRef.current, "registration-complete");
				onSuccess(response);
			},
		},
	};

	useEffect(() => {
		if (!widgetRef.current) return false;
		let widgetInstance;

		import("@okta/okta-signin-widget")
			.then(({ default: OktaSignIn }) => {
				if (widgetRef.current) {
					localStorage.removeItem("okta-cache-storage");

					widgetInstance = new OktaSignIn(widgetConfig);
					widgetInstance.showSignInAndRedirect({ el: widgetRef.current });

					// Initialize when widget loads on Login form
					const widgetInitInterval = setInterval(() => {
						const signUpElement =
							widgetRef.current.getElementsByClassName("registration-link")[0];

						if (signUpElement) {
							clearInterval(widgetInitInterval);

							// Remove help link
							const helpElement =
								widgetRef.current.getElementsByClassName("js-help-link")[0];
							if (helpElement) {
								helpElement.parentNode.removeChild(helpElement);
							}

							const forgotElement =
								widgetRef.current.getElementsByClassName(
									"js-forgot-password"
								)[0];
							if (forgotElement) {
								forgotElement.addEventListener("click", () => {
									updateWidget(widgetRef.current, "forgot");
								});
								forgotElement.setAttribute("data-click", true);
							}

							const unlockElement =
								widgetRef.current.getElementsByClassName("js-unlock")[0];
							if (unlockElement) {
								unlockElement.addEventListener("click", () => {
									updateWidget(widgetRef.current, "unlock");
								});
								unlockElement.setAttribute("data-click", true);

								setupUnlockAccountObserver(widgetRef.current);
							}

							const registrationContainer = signUpElement.closest(
								".registration-container"
							);

							if (isShowSignup) {
								signUpElement.addEventListener("click", e => {
									e.preventDefault();
									setIsAccountVerified(false);
									setVerifyStatus(false);
									setWidgetFlow("signup");
								});
								signUpElement.setAttribute("data-click", true);

								registrationContainer.classList.add("show");
							} else {
								registrationContainer.parentNode.removeChild(
									registrationContainer
								);
							}

							// IDP Discovery Next Button
							const nextButton = widgetRef.current.querySelectorAll(
								"#idp-discovery-submit"
							)[0];
							if (nextButton) {
								nextButton.addEventListener("click", () => {
									setTimeout(() => {
										updateWidget(widgetRef.current, "sign-in");
									}, delay);
								});

								nextButton.setAttribute("data-click", true);
							}

							// Social media buttons
							updateSocialButtons(widgetRef.current);

							// Detection for account actions and defaults to screen based on querystring
							if (isAction === "forgot") {
								forgotElement.click();
							} else if (isAction === "unlock") {
								unlockElement.click();
							} else if (loginHint || email) {
								const updateHint = (usernameElement, userEmail) => {
									if (usernameElement && userEmail) {
										usernameElement.value = decodeURIComponent(userEmail);
										usernameElement.dispatchEvent(
											new Event("change", { bubbles: true })
										);
									}
								};

								// Auto fill the email when new account creation is sucessful
								const usernameSignIn = widgetRef.current.querySelectorAll(
									"#okta-signin-username"
								)[0];
								const usernameIdp = widgetRef.current.querySelectorAll(
									"#idp-discovery-username"
								)[0];
								if (usernameSignIn) {
									updateHint(usernameSignIn, loginHint || email);
									const passwordField = widgetRef.current.querySelectorAll(
										"#okta-signin-password"
									)[0];
									if (passwordField) {
										setTimeout(() => {
											passwordField.focus();
										}, 500);
									}
								} else if (usernameIdp) {
									updateHint(usernameIdp, loginHint || email);
									if (nextButton) {
										nextButton.focus();
									}
								}
							}

							if (isAction) {
								// Modify action query string so if user cancels flow it will not default again
								cleanQueryURL(["action"]);
							}

							const oktaHeader = widgetRef.current.getElementsByClassName(
								"okta-sign-in-header"
							)?.[0];

							if (isAccountCreated || (isAccountVerified && verifyStatus)) {
								if (oktaHeader) {
									// Inject account creation status into widget
									const activateElement = document.createElement("div");
									activateElement.className = `account-activation ${
										isAccountVerified ? "verify" : ""
									}`;

									const headingElement = document.createElement("span");
									headingElement.className = "heading";

									const headingIcon = document.createElement("span");
									headingIcon.className = isAccountVerified
										? "swg-icon swg-icon-checked"
										: "swg-icon swg-icon-checked--o";
									headingElement.appendChild(headingIcon);

									const headingText = document.createElement("span");
									headingText.className = "status";
									headingText.innerText = isAccountVerified
										? dictionary["verify-successful-class-member-status"]
										: dictionary["okta-widget-new-account-heading"];
									headingElement.appendChild(headingText);

									activateElement.appendChild(headingElement);

									const messageElement = document.createElement("p");
									messageElement.className = "message";

									let message = "";

									if (verifyStatus === "verified") {
										message = "verify-status-verified-message-200";
									} else if (verifyStatus === "account-created") {
										message = "verify-status-verified-message-201";
									}

									messageElement.innerText = isAccountVerified
										? dictionary[message]
										: dictionary["okta-widget-new-account-message"];
									activateElement.appendChild(messageElement);

									oktaHeader.appendChild(activateElement);

									if (isAccountCreated || isAccountVerified) {
										oktaHeader.classList.add("activate");
									}

									// Modify registration query strings so if the user bookmarks, the success message will not display again
									cleanQueryURL(["type_hint", "session_hint"]);

									setIsAccountVerified(false);
								}
							} else if (oktaHeader?.classList.contains("activate")) {
								// Remove account created message
								oktaHeader.classList.remove("activate");
							}
						}
					}, 150);
				}
			})
			.catch(e => console.warn(e));

		return () => {
			if (widgetInstance) {
				if (observer !== null) {
					// Stop observing unlock view
					observer.disconnect();
				}

				widgetInstance.remove();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isSocialNotify === false) {
			updateSocialButtons(widgetRef.current, selectedIdp);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSocialNotify, selectedIdp]);

	return <div className={`sso-widget ${className}`} ref={widgetRef} />;
};

OktaSignInWidgetClassic.propTypes = {
	lang: PropTypes.oneOf(["en", "fr"]),
	isAccountCreated: PropTypes.bool.isRequired,
	isAccountVerified: PropTypes.bool.isRequired,
	setIsAccountVerified: PropTypes.func.isRequired,
	isActionDefault: PropTypes.string,
	dictionary: PropTypes.object.isRequired,
	isShowSignup: PropTypes.bool.isRequired,
	className: PropTypes.string.isRequired,
	email: PropTypes.string,
	setWidgetFlow: PropTypes.func.isRequired,
	isSocialNotify: PropTypes.bool.isRequired,
	setSocialDetails: PropTypes.func.isRequired,
	setDisplaySocialModal: PropTypes.func.isRequired,
	selectedIdp: PropTypes.string,
	verifyStatus: PropTypes.string,
	setVerifyStatus: PropTypes.func.isRequired,
};

OktaSignInWidgetClassic.defaultProps = {
	lang: "en",
	isActionDefault: undefined,
	email: undefined,
	selectedIdp: undefined,
	verifyStatus: undefined,
};

export default OktaSignInWidgetClassic;
