/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useContext, useState } from "react";
import PropTypes from "prop-types";
import { Button, Section } from "@sunwing/components";
import { RCL as useTranslation } from "../RCL";
import { Modal } from "../Modal";
import { Verify } from "../Verify/Verify";
import { ProfileContext } from "./context/ProfileProvider";
import {
	OktaSignInWidgetClassic,
	OktaSignInWidgetIE,
} from "./Components/OktaSignInWidget";
import { replaceWithJSX, setCookie } from "../utils/helpers/helper";
import { DotsLoader } from "../Common/Loader";
import { routingPaths } from "./config";

import styles from "./LayoutWrapper.module.scss";

const LayoutWrapper = ({
	language,
	isShowSignup,
	isAccountCreated,
	isAccountVerified,
	setIsAccountVerified,
	widgetFlow,
	setWidgetFlow,
	setModalOpen,
	isModalOpen,
}) => {
	const { profile, updateProfile } = useContext(ProfileContext);
	const [verifyStatus, setVerifyStatus] = useState();
	const [modalContent, setModalContent] = useState();
	const [isSocialNotify, setIsSocialNotify] = useState(true);
	const [socialDetails, setSocialDetails] = useState({});
	const [selectedIdp, setSelectedIdp] = useState();
	const [displaySocialModal, setDisplaySocialModal] = useState(false);
	const dictionary = {
		"okta-widget-sign-in-with-facebook": useTranslation({
			searchKey: "okta-widget-sign-in-with-facebook",
		}),
		"okta-widget-sign-in-with-google": useTranslation({
			searchKey: "okta-widget-sign-in-with-google",
		}),
		"okta-widget-sign-in-with-linkedin": useTranslation({
			searchKey: "okta-widget-sign-in-with-linkedin",
		}),
		"okta-widget-sign-in-with-apple": useTranslation({
			searchKey: "okta-widget-sign-in-with-apple",
		}),
		"okta-widget-email-placeholder": useTranslation({
			searchKey: "okta-widget-email-placeholder",
		}),
		"okta-widget-password": useTranslation({
			searchKey: "okta-widget-password",
		}),
		"okta-widget-confirm-password": useTranslation({
			searchKey: "okta-widget-confirm-password",
		}),
		"okta-widget-confirm-password-error": useTranslation({
			searchKey: "okta-widget-confirm-password-error",
		}),
		"okta-widget-need-help": useTranslation({
			searchKey: "okta-widget-need-help",
		}),
		"okta-widget-unlock-account": useTranslation({
			searchKey: "okta-widget-unlock-account",
		}),
		"okta-widget-error-E0000004": useTranslation({
			searchKey: "okta-widget-error-E0000004",
		}),
		"okta-widget-error-E0000006": useTranslation({
			searchKey: "okta-widget-error-E0000006",
		}),
		"okta-widget-error-E0000119": useTranslation({
			searchKey: "okta-widget-error-E0000119",
		}),
		"okta-widget-chars": useTranslation({ searchKey: "okta-widget-chars" }),
		"okta-widget-numbers": useTranslation({ searchKey: "okta-widget-numbers" }),
		"okta-widget-lowercase": useTranslation({
			searchKey: "okta-widget-lowercase",
		}),
		"okta-widget-uppercase": useTranslation({
			searchKey: "okta-widget-uppercase",
		}),
		"okta-widget-symbols": useTranslation({ searchKey: "okta-widget-symbols" }),
		"okta-widget-username": useTranslation({
			searchKey: "okta-widget-username",
		}),
		"okta-widget-consent-label": useTranslation({
			searchKey: "okta-widget-consent-label-v2",
		}),
		"okta-widget-sign-up-label": useTranslation({
			searchKey: "okta-widget-sign-up-label",
		}),
		"okta-widget-sign-up-text": useTranslation({
			searchKey: "okta-widget-sign-up-text",
		}),
		"okta-widget-create-an-account": useTranslation({
			searchKey: "okta-widget-create-an-account",
		}),
		"okta-widget-new-account-heading": useTranslation({
			searchKey: "okta-widget-new-account-heading",
		}),
		"okta-widget-new-account-message": useTranslation({
			searchKey: "okta-widget-new-account-message",
		}),
		"okta-widget-legal": useTranslation({ searchKey: "okta-widget-legal" }),
		"okta-helplinks-custom-qba": useTranslation({
			searchKey: "okta-helplinks-custom-qba",
		}),
		"okta-profile-panel-first-name": useTranslation({
			searchKey: "okta-profile-panel-first-name",
		}),
		"okta-profile-panel-last-name": useTranslation({
			searchKey: "okta-profile-panel-last-name",
		}),
		"okta-widget-enter-your-first-name": useTranslation({
			searchKey: "okta-widget-enter-your-first-name",
		}),
		"okta-widget-enter-your-last-name": useTranslation({
			searchKey: "okta-widget-enter-your-last-name",
		}),
		"okta-email-flow-error": useTranslation({
			searchKey: "okta-email-flow-error",
		}),
		"okta-widget-sign-up-confirm-text": useTranslation({
			searchKey: "okta-widget-sign-up-confirm-text",
		}),
		// OIE specific
		"okta-widget-goback-text": useTranslation({
			searchKey: "okta-widget-goback-text",
		}),
		"okta-widget-sign-in-title": useTranslation({
			searchKey: "okta-widget-sign-in-title",
		}),
		"okta-widget-social-text-v2": useTranslation({
			searchKey: "okta-widget-social-text-v2",
		}),
		"okta-helplinks": useTranslation({
			searchKey: "okta-helplinks-custom-example",
		}),
		"okta-widget-verify-heading": useTranslation({
			searchKey: "okta-widget-verify-heading",
		}),
		verify: useTranslation({
			searchKey: "verify",
		}),
		"okta-profile-my-trips-dob": useTranslation({
			searchKey: "okta-profile-my-trips-dob",
		}),
		"indicates-required-field": useTranslation({
			searchKey: "indicates-required-field",
		}),
		"okta-widget-back-to-sign-in": useTranslation({
			searchKey: "okta-widget-back-to-sign-in",
		}),
		"verify-email-verification-heading": useTranslation({
			searchKey: "verify-email-verification-heading",
		}),
		"verify-email-notfication-message": useTranslation({
			searchKey: "verify-email-notfication-message",
		}),
		"verify-proceed-to-sign-in": useTranslation({
			searchKey: "verify-proceed-to-sign-in",
		}),
		"verify-proceed-to-contact-us": useTranslation({
			searchKey: "verify-proceed-to-contact-us",
		}),
		"verify-successful-class-member-status": useTranslation({
			searchKey: "verify-successful-class-member-status",
		}),
		"verify-status-verified-message-200": useTranslation({
			searchKey: "verify-status-verified-message-200",
		}),
		"verify-status-verified-message-201": useTranslation({
			searchKey: "verify-status-verified-message-201",
		}),
		"okta-profile-feedback-loading-profile-verify-error": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-error",
		}),
		"okta-profile-feedback-loading-profile-verify-fail": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-fail",
		}),
		"modal-close": useTranslation({
			searchKey: "modal-close",
		}),
		"okta-profile-feedback-loading-profile-verify-EAU0002": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-EAU0002",
		}),
		"okta-profile-feedback-loading-profile-verify-EAU0001": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-EAU0001",
		}),
		"okta-profile-feedback-loading-profile-verify-EXX0002": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-EXX0002",
		}),
		"okta-profile-feedback-loading-profile-verify-EXX0000": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-EXX0000",
		}),
		"okta-profile-feedback-loading-profile-verify-generic": useTranslation({
			searchKey: "okta-profile-feedback-loading-profile-verify-generic",
		}),
		"social-login-facebook-notify": useTranslation({
			searchKey: "social-login-facebook-notify",
		}),
		"social-login-apple-notify": useTranslation({
			searchKey: "social-login-apple-notify",
		}),
	};

	useEffect(() => {
		const errorHeading = (
			<div className={styles.statusHeading}>
				{dictionary["okta-profile-feedback-loading-profile-verify-error"]}
			</div>
		);

		if (profile?.userStatus) {
			setVerifyStatus(profile.userStatus);
		} else if (!verifyStatus) {
			setModalOpen(false);
		}

		switch (verifyStatus) {
			case "loading":
				setModalContent(
					<>
						<Section className={styles.parent}>
							<DotsLoader className={styles.loader} />
						</Section>
					</>
				);
				break;
			case "verified":
				setIsAccountVerified(true);
				setModalOpen(false);
				updateProfile({
					userStatus: "",
				});
				setWidgetFlow("login");
				break;

			case "account-created":
				setModalContent(
					<>
						<div className={styles.statusHeading}>
							{dictionary["verify-email-verification-heading"]}
						</div>
						<div className={styles.statusMessage}>
							{replaceWithJSX(
								dictionary["verify-email-notfication-message"],
								"{email}",
								profile.email
							)}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							onClick={() => {
								setIsAccountVerified(true);
								setModalOpen(false);
								updateProfile({
									userStatus: "",
								});
								setWidgetFlow("login");
							}}
						>
							{dictionary["verify-proceed-to-sign-in"]}
						</Button>
					</>
				);
				setModalOpen(true);

				// eslint-disable-next-line no-case-declarations
				const expiration = new Date();
				expiration.setUTCMonth(expiration.getMonth() + 1);
				setCookie(
					"okta_user_lang",
					language.toLowerCase(),
					"/",
					".sunwing.ca",
					expiration
				);
				break;

			case "invalid-user-EAU0002":
				setModalContent(
					<>
						{errorHeading}
						<div className={styles.statusMessage}>
							{
								dictionary[
									"okta-profile-feedback-loading-profile-verify-EAU0002"
								]
							}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							to={`${routingPaths[language].claim}`}
						>
							{dictionary["verify-proceed-to-contact-us"]}
						</Button>
					</>
				);
				setModalOpen(true);
				break;

			case "invalid-user-EAU0001":
				setModalContent(
					<>
						<div className={styles.statusMessage}>
							{
								dictionary[
									"okta-profile-feedback-loading-profile-verify-EAU0001"
								]
							}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							onClick={() => {
								setModalOpen(false);
								setVerifyStatus(undefined);
								updateProfile({
									userStatus: "",
								});
								setWidgetFlow("login");
							}}
						>
							{dictionary["verify-proceed-to-sign-in"]}
						</Button>
					</>
				);
				setModalOpen(true);
				break;

			case "invalid-user-EXX0002":
				setModalContent(
					<>
						<div className={styles.statusMessage}>
							{
								dictionary[
									"okta-profile-feedback-loading-profile-verify-EXX0002"
								]
							}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							onClick={() => {
								setModalOpen(false);
								setVerifyStatus(undefined);
								updateProfile({
									userStatus: "",
								});
							}}
						>
							{dictionary["modal-close"]}
						</Button>
					</>
				);
				setModalOpen(true);
				break;

			case "invalid-user-EXX0000":
				setModalContent(
					<>
						<div className={styles.statusMessage}>
							{
								dictionary[
									"okta-profile-feedback-loading-profile-verify-EXX0000"
								]
							}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							onClick={() => {
								setModalOpen(false);
								setVerifyStatus(undefined);
								updateProfile({
									userStatus: "",
								});
							}}
						>
							{dictionary["modal-close"]}
						</Button>
					</>
				);
				setModalOpen(true);
				break;

			default:
				setModalContent(
					<>
						<div className={styles.statusMessage}>
							{
								dictionary[
									"okta-profile-feedback-loading-profile-verify-generic"
								]
							}
						</div>
						<Button
							className={styles.signinButton}
							type="button"
							onClick={() => {
								setModalOpen(false);
								setVerifyStatus(undefined);
								updateProfile({
									userStatus: "",
								});
							}}
						>
							{dictionary["modal-close"]}
						</Button>
					</>
				);
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isModalOpen, profile?.email, verifyStatus, isAccountVerified]);

	if (
		!isShowSignup ||
		(widgetFlow === "login" &&
			(isAccountVerified ||
				isAccountCreated ||
				(!isAccountCreated && !isAccountVerified)))
	) {
		return (
			<>
				{process.env.REACT_APP_OKTA_IE === "true" ? (
					<OktaSignInWidgetIE
						key={language}
						lang={language}
						isAccountCreated={isAccountCreated}
						isAccountVerified={isAccountVerified}
						setIsAccountVerified={setIsAccountVerified}
						dictionary={dictionary}
						isShowSignup={isShowSignup}
						className="oie"
						flow="login"
						email={profile?.email}
						setWidgetFlow={setWidgetFlow}
						isSocialNotify={isSocialNotify}
						setSocialDetails={setSocialDetails}
						setDisplaySocialModal={setDisplaySocialModal}
						selectedIdp={selectedIdp}
						verifyStatus={verifyStatus}
						setVerifyStatus={setVerifyStatus}
					/>
				) : (
					<OktaSignInWidgetClassic
						key={language}
						lang={language}
						isAccountCreated={isAccountCreated}
						isAccountVerified={isAccountVerified}
						setIsAccountVerified={setIsAccountVerified}
						dictionary={dictionary}
						isShowSignup={isShowSignup}
						className="classic"
						email={profile?.email}
						setWidgetFlow={setWidgetFlow}
						isSocialNotify={isSocialNotify}
						setSocialDetails={setSocialDetails}
						setDisplaySocialModal={setDisplaySocialModal}
						selectedIdp={selectedIdp}
						verifyStatus={verifyStatus}
						setVerifyStatus={setVerifyStatus}
					/>
				)}

				{displaySocialModal && (
					<Modal
						className={styles.layoutModal}
						modalContent={
							<>
								<Section className={styles.parent}>
									<div className={styles.statusMessage}>
										{socialDetails?.idp?.type === "FACEBOOK" &&
											dictionary["social-login-facebook-notify"]}

										{socialDetails?.idp?.type === "APPLE" &&
											dictionary["social-login-apple-notify"]}
									</div>
									<div className={styles.buttonWrapper}>
										<Button
											className={styles.signinButton}
											type="button"
											onClick={() => {
												setDisplaySocialModal(false);
												setIsSocialNotify(false);
												setSelectedIdp(socialDetails?.idp?.id);
											}}
										>
											{dictionary["verify-proceed-to-sign-in"]}
										</Button>
									</div>
								</Section>
							</>
						}
						isOpenDefault={displaySocialModal}
						modalType="custom"
						onClose={() => {
							setDisplaySocialModal(false);
						}}
					/>
				)}
			</>
		);
	}

	return (
		<>
			<Verify
				language={language}
				dictionary={dictionary}
				setWidgetFlow={setWidgetFlow}
				setModalOpen={setModalOpen}
				setVerifyStatus={setVerifyStatus}
			/>

			{isModalOpen && verifyStatus && (
				<Modal
					className={styles.layoutModal}
					modalContent={modalContent}
					isOpenDefault={isModalOpen}
					modalType="custom"
					onClose={() => {
						setModalOpen(false);
						setVerifyStatus(undefined);
						updateProfile({
							userStatus: "",
						});
					}}
				/>
			)}
		</>
	);
};

LayoutWrapper.propTypes = {
	language: PropTypes.oneOf(["en", "fr"]).isRequired,
	isShowSignup: PropTypes.bool,
	isAccountCreated: PropTypes.bool.isRequired,
	isAccountVerified: PropTypes.bool.isRequired,
	setIsAccountVerified: PropTypes.func.isRequired,
	widgetFlow: PropTypes.string.isRequired,
	setWidgetFlow: PropTypes.func.isRequired,
	setModalOpen: PropTypes.func.isRequired,
	isModalOpen: PropTypes.bool.isRequired,
};

LayoutWrapper.defaultProps = {
	isShowSignup: false,
};

export default LayoutWrapper;
export { LayoutWrapper };
