import React, { useContext, useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import { Routes, Route, Link as LinkRouter } from "react-router-dom";
import { Header, Icon } from "@sunwing/components";
import "@sunwing/components/dist/index.css";
import { RCL, RCL as useTranslation, useLocale } from "./RCL";
import { routingPaths, segmentRouteUpdate } from "./Account/config";
import { ProfileContext } from "./Account/context/ProfileProvider";
import { Login } from "./Login/Login";
import { Callback } from "./Account/Callback";
import { Home } from "./Home/Home";
import { Agent } from "./Agent/Agent";
import { Footer } from "./Footer/Footer";
import { Logout } from "./Account/Logout";
import { ErrorPage } from "./404";
import { Claim } from "./Claim/Claim";
import { Help } from "./Help/Help";
import CookieAcceptanceBanner from "./CookieAcceptanceBanner";
import { Modal } from "./Modal";
import style from "./Header/Header.module.scss";
import "./styles/index.scss";

const App = () => {
	const {
		profile,
		isUserLoggedIn,
		setEnableSSO,
		serverDate,
		isValidUser,
		isVerified,
	} = useContext(ProfileContext);

	const dictionary = {
		"qba-breadcrumb-home": useTranslation({ searchKey: "qba-breadcrumb-home" }),
	};

	const { status, lang } = useLocale();
	const [isShowSignup, setShowSignup] = useState();
	const [isShowAccess, setShowAccess] = useState();
	const [scripts, setScripts] = useState([]);
	const initScript = useRef(true);
	const language = lang?.toLowerCase();

	const locales = ["en", "fr"];

	const oktaLabel = {
		welcome: useTranslation({ searchKey: "okta-header-welcome" }),
		"a11y-close-menu": useTranslation({ searchKey: "a11y-close-menu" }),
		"okta-header-side-menu-title": useTranslation({
			searchKey: "okta-header-side-menu-title",
		}),
		"okta-header-side-menu-label": useTranslation({
			searchKey: "okta-header-side-menu-label",
		}),
		"okta-header-drop-down-menu-label": useTranslation({
			searchKey: "okta-header-drop-down-menu-label",
		}),
	};

	useEffect(() => {
		if (serverDate) {
			// Determine if registration is still available
			const signupCutOffDate = new Date(process.env.REACT_APP_SIGNUP_CUTOFF);
			const isSignupValid = signupCutOffDate.getTime() > serverDate.getTime();
			setShowSignup(isSignupValid);

			// Determine if access is still available
			const accessCutOffDate = new Date(process.env.REACT_APP_ACCESS_CUTOFF);
			const isAccessValid = accessCutOffDate.getTime() > serverDate.getTime();
			setShowAccess(isAccessValid);
			setEnableSSO(isAccessValid);
		} else if (serverDate === undefined) {
			setShowAccess(true);
		} else {
			setShowAccess(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverDate]);

	useEffect(() => {
		if (initScript.current) {
			// Invoke site-wide scripts in HEAD tag
			const scriptTags = [];
			if (
				process.env.REACT_APP_SEGMENT_WRITE_KEY &&
				process.env.REACT_APP_CREATED_SOURCE
			) {
				scriptTags.push({
					type: "text/javascript",
					innerHTML: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
						analytics.load("${process.env.REACT_APP_SEGMENT_WRITE_KEY}");
						analytics.page({brand_code: 'SWG', app_code: '${process.env.REACT_APP_CREATED_SOURCE}'});
						}}()`,
				});
			}

			setScripts(scriptTags);
			initScript.current = false;
		}
	}, [scripts]);

	if (
		isShowSignup === undefined ||
		isShowAccess === undefined ||
		status === "LOADING"
	) {
		return null;
	}

	return (
		<>
			<Helmet htmlAttributes={{ lang: language }} script={scripts}>
				{locales.map(locale => (
					<link
						key={locale}
						rel="alternate"
						href={`${locale}`}
						hrefLang={locale}
					/>
				))}
			</Helmet>
			{status === "READY" && (
				<>
					<Header
						classNames={{
							header: style.header,
						}}
						currentLanguage={language}
						languagePages={[
							{
								id: "en",
								label: "English",
								lang: "en",
								path: "#",
							},
							{
								id: "fr",
								label: "Français",
								lang: "fr",
								path: "#",
							},
						]}
						logo={
							<>
								{isUserLoggedIn && (
									<LinkRouter
										to={
											isUserLoggedIn
												? `/${language}/${routingPaths[language].home}`
												: "#"
										}
										onClick={() => {
											if (
												isVerified &&
												window.location.pathname !==
													`/${language}/${routingPaths[language].home}`
											) {
												segmentRouteUpdate();
											}
										}}
									>
										<img
											alt={dictionary["qba-breadcrumb-home"]}
											src="https://assets.sunwingtravelgroup.com/image/upload/f_svg/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg"
										/>
									</LinkRouter>
								)}
								{!isUserLoggedIn && (
									<img
										alt=""
										src="https://assets.sunwingtravelgroup.com/image/upload/f_svg/v1614627905/sunwing-prod/Web%20Replatform%20Project/Logos/SunwingLogos/NewSunwingLogo-white.svg"
									/>
								)}
							</>
						}
						labels={
							isVerified
								? {
										oktaWelcome: oktaLabel.welcome,
										oktaCloseMenu: oktaLabel["a11y-close-menu"],
										oktaMobileMenuTitle:
											oktaLabel["okta-header-side-menu-title"],
										oktaOpenMenu: oktaLabel["okta-header-side-menu-label"],
										oktaToggleDropdown:
											oktaLabel["okta-header-drop-down-menu-label"],
								  }
								: {}
						}
						profileData={
							isVerified
								? {
										isUserLoggedIn,
										userName: profile?.firstName || "",
								  }
								: {}
						}
						menuLinks={null}
					>
						{isUserLoggedIn && (
							<div slot="okta">
								<ul className={style.accountMenu}>
									{!profile?.agentGlobalId && isVerified && (
										<>
											<li className={style.listItem}>
												<Modal
													className={style.myAccount}
													buttonContent={
														<>
															<span aria-hidden="true">
																<Icon name="my-account" aria-hidden="true" />
															</span>
															<span className={style.textIndent}>
																<RCL searchKey="okta-header-menu-account-information" />
															</span>
														</>
													}
													modalTarget="_blank"
													modalUrl={`${process.env.REACT_APP_SWG_DOMAIN}/user-account/login?loadSession=true&tab=profile`}
													onClose={() => {
														// Close okta menu for mobile
														const oktaMobileButton = document.getElementById(
															"account-slide-out-menu-close-button"
														);

														if (oktaMobileButton.ariaExpanded === "true") {
															oktaMobileButton.click();
														}

														// Close okta menu for desktop
														const oktaDesktopButton =
															document.getElementById("accountSignin");

														if (oktaDesktopButton.ariaExpanded === "true") {
															oktaDesktopButton.click();
														}
													}}
													onOpen={() => {
														// Close okta menu for mobile
														const oktaMobileButton = document.getElementById(
															"account-slide-out-menu-close-button"
														);

														if (oktaMobileButton.ariaExpanded === "true") {
															oktaMobileButton.click();
														}
													}}
												/>
											</li>

											<li className={style.divider} />
										</>
									)}

									<li className={style.listItem}>
										<Logout language={language} />
									</li>
								</ul>
							</div>
						)}
					</Header>
					<main className={style.mainBody}>
						<Routes>
							<Route
								exact
								path="/"
								element={
									<Login
										language={language}
										isShowAccess={isShowAccess}
										isShowSignup={isShowSignup}
									/>
								}
							/>

							<Route
								path={`${language}/${routingPaths[language].login}`}
								element={
									<Login
										language={language}
										isShowAccess={isShowAccess}
										isShowSignup={isShowSignup}
									/>
								}
							/>

							<Route
								path={`${language}/${routingPaths[language].home}`}
								element={<Home language={language} isValidUser={isValidUser} />}
							/>

							<Route
								path={`${language}/${routingPaths[language].claim}`}
								element={<Claim language={language} />}
							/>

							<Route
								path={`${language}/${routingPaths[language].callback}`}
								element={
									<Callback language={language} isValidUser={isValidUser} />
								}
							/>

							<Route
								path={`${language}/${routingPaths[language].agent}`}
								element={<Agent language={language} />}
							/>

							<Route
								path={`${language}/${routingPaths[language].help}`}
								element={<Help language={language} />}
							/>

							<Route path="*" element={<ErrorPage lang={language} />} />
						</Routes>
					</main>
					<Footer />
					<CookieAcceptanceBanner />
				</>
			)}
		</>
	);
};

export default App;
