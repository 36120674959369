import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Security } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import { useNavigate } from "react-router-dom";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { config, routingPaths } from "../config";

const SecurityProvider = ({ children, lang }) => {
	const navigate = useNavigate();
	const [oktaAuth, setOktaAuth] = useState();
	const language = lang.substring(0, 2);

	const restoreOriginalUri = async () => {
		const routing = routingPaths[language];
		const redirectAfterLoginUrl = `/${language}/${routing.profile}?isLogin=true`;

		navigate(redirectAfterLoginUrl);
	};

	useEffect(() => {
		const oktaAuthInstance = new OktaAuth({
			...config,
			redirectUri: `${window.location.origin}/${language}/login/callback`,
		});

		setOktaAuth(oktaAuthInstance);
	}, [language]);

	if (!oktaAuth) return children;

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			{children}
		</Security>
	);
};

SecurityProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	lang: PropTypes.string,
};

SecurityProvider.defaultProps = {
	lang: "en",
};

export default SecurityProvider;
export { SecurityProvider };
